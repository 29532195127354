.Button {
  /*Reset's every elements apperance*/
  all: unset;



  text-transform: uppercase;
  font-weight: bold;
  font-size: 16pt;
  cursor: pointer;
  text-align: center;
  filter: drop-shadow(5px 5px 0px rgba(220, 242, 244, 0.3));

  user-select: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  font-family: 'Boogaloo';
}
.Button.red .Button-content { background: #ea5c3f; color: #FFE8EA;}
.Button.red .Button-icon {
  filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.2)) invert(100%) sepia(41%) saturate(1353%) hue-rotate(287deg) brightness(99%) contrast(106%);
}

.Button:hover  {
  filter: drop-shadow(0 0 10px #ffc440) drop-shadow(5px 5px 0px #ffc34070); /* la shadow qui suit le contour */
}
.Button:hover .Button-content {
  background: #ffc440 !important;
  color: #FFF;
  clip-path: polygon(4% 8%, 94% 2%, 97% 97%, 2% 98%) !important;
}
.Button:hover .Button-icon {
  filter: invert() drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.2));
}

.Button.with-icon {
  /* height: 150px; */
  max-width: 350px;
  min-width: 250px;
}


.Button .Button-content {
  padding: 20px;
  clip-path: polygon(5% 5%, 95% 0%, 100% 100%, 0% 95%);
  background: #dcf2f4;
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  gap: 10px;

  font-size: 23pt;
  text-shadow: 4px 4px 0px rgba(0, 0, 0, 0.15);

  transition: clip-path 300ms;
}
/*
NOTE
les valeurs des filter pour gérer les couleurs des icones sont générées grâce à ce tool bien pratique
https://codepen.io/sosuke/pen/Pjoqqp
*/
.Button .Button-icon {
  max-height: 65px;
  filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.2)) invert(21%) sepia(15%) saturate(922%) hue-rotate(160deg) brightness(102%) contrast(87%);
}



.Button .Button-content .Button-text{
  /* .Button:not(.with-icon) .Button-content .Button-text{ */
  padding: 0 20px;

}
.Button a{
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 700px) {
   .Button .Button-content { font-size: 15pt; }
  .Button .Button-icon { max-height: 35px; }

  .Button.with-icon {

    max-width: 350px;
    min-width: 150px;
  }
}