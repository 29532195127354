.Header {
  background: #255C7A;
  color: #FFF;
  margin: 0;
  padding: 20px;
  /* border: 1px solid  red; */
  position: relative;
  width: 100%;
  z-index: 2;
  gap: 20px;
  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

 filter: drop-shadow(0 10px 20px rgba(12, 21, 58, 0.55));

}

.Header > * {
  position: relative;
  z-index: 2;
}
.Header::after {
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  background: lime;

  background: #255C7A;

  top: 100%;
  left: 0;
  z-index: 0;
  transform: translateY(-22px) skewY(-1deg);
}


.Header h1{
  margin: 0;
  margin-right: 20px;
}


.Header .spacer { flex-grow: 1; }

.Header .pseudo { font-size: 20pt; }

.Header .burger {
  width: 50px;
  height: 50px;

  border-top: 10px solid #FFF;
  border-bottom: 10px solid #FFF;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}
.Header .burger::before{
  display: block;
  content: "";
  width: 100%;
  height: 10px;
  background: #FFF;


}
