.Footer {
  background: #255C7A;
  color: #FFF;

  width: 100%;

  padding: 15px 30px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
 filter: drop-shadow(0 -10px 20px rgba(12, 21, 58, 0.55));
 /* filter: drop-shadow(0 -10px 30px red); */

 font-family: 'Boogaloo', sans-serif;


}

.Footer > * {
  position: relative;
  z-index: 2;
}
.Footer::after {
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  background: #255C7A;
  /* background: lime; */
  top: 0%;
  left: 0;
  z-index: 0;

  transform: translateY(-22px) skewY(1deg);
  /* box-shadow: 0 -10px 30px rgba(12, 21, 58, 0.4); */

}


.Footer .logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 0 40px;
}

.Footer .logos .logo {
  padding: 5px 8px;
  background: #FFF;
  border-radius: 10px;
}

.Footer .logos img{
  width: auto;
  max-height: 80px;
  max-width: 180px;

}

.Footer  a{ color: inherit; }
.Footer .baseline img {
  width: 40px;
}
.Footer .baseline{
  text-transform: uppercase;
  font-size: 12pt;

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.Footer .menu-bottom{
  display: flex;
  gap: 10px;
  align-items: center;

  font-size: 18pt;
}

@media screen and (max-width: 900px) {

  .Footer .logos img{
    max-width: 130px;
    max-height: 60px;
  }

}
@media screen and (max-width: 600px) {

  .Footer .logos .logo{
    padding: 3px;
  }
  .Footer .logos img{
    max-width: 100px;
    max-height: 40px;
  }

}