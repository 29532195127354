.HeroSection {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 40px;
}
.HeroSection .HeroSection-title{
  height: 60vh;
  width: 100%;

  display: flex;
  flex-direction: column;
}
.HeroSection .HeroSection-title h2{
  font-size: 30pt;
  margin: 0;
  padding: 0px;
  margin: 30px 0;
  padding: 0 40px;
  text-align: center;
  color: #D9EFF1;
  text-shadow: 3px 3px 0px #383A3C;
  font-weight: normal;
}

.HeroSection .HeroSection-title h1{
  margin: 0;
  width: 100%;

  background: url(../../../images/logo.png) center center no-repeat;
  background-size: contain;

  flex-grow: 1;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;



}

.HeroSection .HeroSection-title h1 img{
  display: none;
}


.HeroSection .HeroSection-content{
  flex-grow: 1;

  background: linear-gradient(to right, transparent, #255E7E 35%, #255E7E 65%, transparent);
  margin-top: 100px;
  padding-top: 100px;


  position: relative;
  box-shadow: 0 10px 30px rgba(12, 21, 58, 0.4);

  position: relative;
}
.HeroSection .HeroSection-content .play-button{
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%)  translateY(-50%);
}


.HeroSection .HeroSection-content .HeroSection-description{
  width: 70%;
  margin: 0 auto;
  text-align: center;

  font-size: 20pt;
  padding: 20px 0;
  color: #D9EFF1;



}

.HeroSection .HeroSection-content .drone {
  position: absolute;
  top: 0;
  right: 20px;
  transform:  translateY(-50%);

  width: 20vw;
  min-width: 250px;
  max-width: 500px;
  max-height: none;

  animation: float 4s ease-in-out infinite;
}

@keyframes float {

  0% { transform:  translateY(-50%); }
  50% { transform:  translateY(-40%); }

}



@media screen and (max-width: 700px) {
  .HeroSection .HeroSection-title h2{
    /* padding: 20px; */
    font-size: 24pt;
  }

  .HeroSection .HeroSection-content .drone {
    display: none;
  }
}
