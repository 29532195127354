.ScreenGame  {
  width: 0;
  height: 0;
  opacity: 0;

}

.ScreenGame #game-content {
  height: 100%;
}
.ScreenGame.visible {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  opacity: 1;
  background: #000;

  overflow: hidden;
}