.Notifs {
  position: fixed;
  bottom: 0;
  right: 0;

  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 8px;

}
.Notifs .notif {
  background: #FFF;
  padding: 5px 15px;

  cursor: pointer;

  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.418);


}

.Notifs .notif.ERROR {
  border-bottom: 3px solid red;
}