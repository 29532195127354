.PopupEndgame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.PopupEndgame .spacer {
  flex-grow: 1;
}
.PopupEndgame .PopupEndgame-content h2{
  font-size: 44px;
}
.PopupEndgame .PopupEndgame-content {
  position: relative;
  text-align: center;
  background: linear-gradient(to right, transparent, #255E7E 35%, #255E7E 65%, transparent);
  margin-top: 100px;

  width: 100%;

  color: #FFF;
  box-shadow: 0 10px 30px rgba(12, 21, 58, 0.4);

}

.PopupEndgame .PopupEndgame-content-text a{ color: #FFF;}
.PopupEndgame-content-text {
  max-width: 60%;
  margin: 0 auto;
  font-size: 22px;
}

.PopupEndgame .PopupEndgame-content-buttons {
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */

  transform:  translateY(50%);
}


.PopupEndgame-content-button {
  /* margin: 0 auto; */
}


.PopupEndgame .drone {
  position: absolute;
  top: 0;
  right: 200px;
  transform:  translateY(-50%);

  width: 20vw;
  min-width: 250px;
  max-width: 500px;
  max-height: none;

  animation: float 4s ease-in-out infinite;
}

@keyframes float {

  0% { transform:  translateY(-50%); }
  50% { transform:  translateY(-40%); }

}



@media screen and (max-width: 1000px) {
  .PopupEndgame .drone {
    right: 50px;
  }
}

@media screen and (max-width: 700px) {
  .PopupEndgame .drone {
    display: none;
  }
}
