@import "../../fonts/stylesheet.css";

* {box-sizing: border-box;}

:root {
  --main-color: #2E4451;
  --accent-color: #ffc440;
}

html, body, #root, .App {
  margin: 0;
  padding: 0;
  font-family: 'Arimo', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Boogaloo', sans-serif;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.screen {
  flex-grow: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}
.screen .screen-content {
  flex-grow: 1;
}


.Loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}


button.link-btn {

  background: none;
  border: none;
  padding: 0;
  color: darkblue;
  cursor: pointer;
}
button.link-btn:hover { text-decoration: underline; }


.error { color: rgb(228, 72, 72); }


.App * {
  scrollbar-width: thin;
  scrollbar-color: rgb(197, 197, 197) rgb(241, 241, 241);
}

::-webkit-scrollbar { width: 10px; }

::-webkit-scrollbar-track {
  overflow: visible;
  background:#334550;
}

::-webkit-scrollbar-thumb {
  background: #DCF2F4 ;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover { background:rgb(219, 219, 219); }
