@font-face {
    font-family: 'Arimo';
    src: url('Arimo-SemiBold.woff2') format('woff2'),
        url('Arimo-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-MediumItalic.woff2') format('woff2'),
        url('Arimo-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-SemiBoldItalic.woff2') format('woff2'),
        url('Arimo-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-BoldItalic.woff2') format('woff2'),
        url('Arimo-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-Bold.woff2') format('woff2'),
        url('Arimo-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-Medium.woff2') format('woff2'),
        url('Arimo-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-Italic.woff2') format('woff2'),
        url('Arimo-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arimo';
    src: url('Arimo-Regular.woff2') format('woff2'),
        url('Arimo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

