.Checkbox  {

  text-transform: uppercase;

  display: flex;
  align-items: center;

  gap: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
}


.Checkbox input{
  display: none;
  margin: 0;
  padding: 0;
}


.Checkbox span{
  cursor: pointer;
  display: inline-block;

  width: 40px;
  height: 40px;

  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  user-select: none;

  font-size: 70px;
  color: inherit;
  color: var(--accent-color);
  font-weight: bold;

  position: relative;
}


.Checkbox span::before{
  content: "";
  width: 100%;
  height: 100%;
  background: #DDF2F5;
  clip-path: polygon(5% 5%, 95% 0%, 100% 100%, 0% 95%);

}
.Checkbox input:checked + span::after{
  content: "✓";
  position: absolute;


}