.SettingsPopup .Popup-content {
  margin-top: 50px;
}
.SettingsPopup .SettingsPopup-option {
  display: flex;
  align-items: center;
  height: 45px;
}
.SettingsPopup .SettingsPopup-option .SettingsPopup-option-text{
  width: 250px;
  text-align: left;
}

.Switch input{
  display: none;
}
.Switch span{
  cursor: pointer;
  display: inline-block;

  width: 40px;
  height: 40px;

  margin: 0 20px;

  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  user-select: none;

  font-size: 70px;
  color: inherit;
  background: #FFF;
  color: var(--accent-color);
  font-weight: bold;

  position: relative;
}


.Switch.checked span::after{
  content: "✓";
  position: absolute;


}