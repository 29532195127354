.PopupVideo {
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.PopupVideo video{
  width: 100%;
  height: 100%;
  pointer-events: none;

}

.PopupVideo .play-button{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%)  translateY(-50%);

}
.PopupVideo .skip-button{
  position: absolute;
  bottom: 10px;
  right: 10px;

}