.ScreenLogin-content {

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  background: url("../../images/background.jpg") center center;
}
.ScreenLogin-content .Popup{
  flex-grow: 1;
}

.ScreenLogin .ScreenLogin-columns{
  display: flex;
  gap: 50px;
}
.ScreenLogin .ScreenLogin-column{ flex-grow: 1; }
.ScreenLogin .LoginForm .ScreenLogin-column:last-child{
  clip-path: polygon(5% 3%, 88% 0%, 100% 100%, 0% 95%);
  padding-top: 20px;
  background: #16425F;

}

.ScreenLogin .Form form > * { margin-bottom: 30px; }


.ScreenLogin .Form{
  padding: 30px;
  font-size: 24pt;
}

.ScreenLogin .Form label.input-label{
  display: block;
  text-align: left;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.ScreenLogin .Form input:not([type="checkbox"]){
  display: block;

  font-size: 16pt;
  padding: 10px 30px;
  text-transform: uppercase;
  width: 100%;
  max-width: none;
  clip-path: polygon(1% 8%, 94% 2%, 97% 97%, 2% 98%) !important;
  background: #DDF2F5;
}

.ScreenLogin .Form input:not([type="checkbox"]):-internal-autofill-selected,
.ScreenLogin .Form input:not([type="checkbox"]):-internal-autofill-selected:hover,
.ScreenLogin .Form input:not([type="checkbox"]):-internal-autofill-selected:focus,
.ScreenLogin .Form input:not([type="checkbox"]):-internal-autofill-selected:active{
  background: #DDF2F5;
}


.ScreenLogin .Form .Form-remember {
  display: flex;
  justify-content: flex-end;
}
.ScreenLogin .Form .Form-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.ScreenLogin .Form .link {
  margin-top: 30px;
  font-size: 24pt;
  color: var(--accent-color);
  cursor: pointer;
  text-decoration: underline;
}
