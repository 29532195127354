.SavegameNotif{
  text-align: center;
  color: #D9EFF1;
  font-size: 17pt;
  font-style: italic;
  width: 90%;
}
.SavegameNotif a{
  color: #D9EFF1;
  font-weight: bold;
}