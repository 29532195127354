.ScrollDownArrow {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%) ;

  animation:jump 1.3s ease 0s infinite normal;

}


@keyframes jump{
  0%{ transform:translateX(-50%) translateY(0); }
  20%{ transform:translateX(-50%) translateY(0); }
  40%{ transform:translateX(-50%) translateY(-20px); }
  50%{ transform:translateX(-50%) translateY(0); }
  60%{ transform:translateX(-50%) translateY(-10px); }
  80%{ transform:translateX(-50%) translateY(0); }
  100%{ transform:translateX(-50%) translateY(0); }
  }