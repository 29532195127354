.TrailerSection {

}



.TrailerSection {

  padding: 80px 40px;
  display: flex;
  gap: 40px;

  display: flex;
  align-items: center;
}



@media screen and (max-width: 900px) {
  .TrailerSection {
    flex-direction: column;
  }
}

.TrailerSection .TrailerSection-clip{
  aspect-ratio: 16/9;
  min-width: 50%;
  width: 100%;

  border: 8px solid #D9EFF1;
  background: #383A3C;

  display: flex;
  justify-content: center;
  align-items: center;
}

.TrailerSection .TrailerSection-description-ctnr{
  filter: drop-shadow(5px 5px 0px rgba(220, 242, 244, 0.3));
}
.TrailerSection .TrailerSection-description{
  padding: 40px;
  padding-bottom: 60px;
  background: #dcf2f4;
  color: var(--main-color);
  font-size: 20pt;

  clip-path: polygon(3% 3%, 98% 1%, 100% 100%, 0% 95%);

}

.TrailerSection .TrailerSection-description h2{
  text-align: center;
  font-size: 24pt;
}



.TrailerSection .youtube-player {
  width: 100%;
  height: 100%;
}
.TrailerSection .youtube-player-iframe {
  width: 100%;
  height: 100%;
}