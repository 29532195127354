.Popup-overlay {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(3, 32, 51, 0.75);

}

.Popup {
  color: #FFF;
  position: relative;
  max-width: 70%;
  max-height: 70%;


  text-align: center;
  display: flex;
  flex-direction: column;

  /* border: 1px solid  red; */
}

.Popup .Popup-close{
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  width: 90px;
  height: 90px;
  min-width: 0;

  transform: translateX(50%)  translateY(0%);
}
.Popup .Popup-close .Button-content{ background: #EA5C3F; }
.Popup .Popup-close .Button-icon {
  filter: drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.2)) invert(100%) sepia(0%) saturate(1%) hue-rotate(324deg) brightness(105%) contrast(101%);
}

.Popup .Popup-title{
  margin-bottom: -40px;
  position: relative;
  z-index: 3;
  filter: drop-shadow(8px 8px 0px rgba(136, 169, 190, 0.2)) ;
}
.Popup .Popup-title h3{
  display: inline-block;
  font-size: 40pt;
  margin: 0;
  padding: 10px 40px;
  background: #ffc440;
  color: #0D2C41;
  clip-path: polygon(0% 2%, 100% 0%, 97% 98%, 2% 95%);
  font-weight: normal;

}


.Popup .Popup-clip-drop-shadow{
  filter: drop-shadow(8px 8px 0px rgba(136, 169, 190, 0.2)) ;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.Popup .Popup-clip {
  clip-path: polygon(3% 2%, 100% 0%, 97% 98%, 0% 95%);

  padding: 80px;
  background: #2B5E82;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;


}


.Popup .Popup-content{

  white-space: pre-wrap;
  height: 80%;
  font-size: 20pt;
  text-align: left;
  background: #417294;
  padding: 30px;


  box-shadow: 8px 8px 30px rgba(12, 21, 58, 0.44);
  overflow-y: auto;
}


.Popup ::-webkit-scrollbar {
  width: 10px;
}

.Popup ::-webkit-scrollbar-track {
  background: #0F293B;
  overflow: visible;

}

.Popup ::-webkit-scrollbar-thumb {
  background:#FFC440;
  border-radius: 10px;
}

.Popup ::-webkit-scrollbar-thumb:hover { background:rgb(219, 219, 219); }